
import { defineComponent } from "vue";
import {
  Form,
  Button,
  Input,
  message,
  Switch,
  Select,
  Upload,
  Image,
} from "ant-design-vue";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons-vue";
import {
  updateAreaByCode,
  saveArea,
  getAreaByCode
} from "@/api/terminalList.js";
import { AreaRules } from "@/rules/rules.js";
const { Item } = Form;
const { Option } = Select;
export default defineComponent({
  components: {
    Form,
    Button,
    FormItem: Item,
    Input,
    Switch,
    PlusOutlined,
    Select,
    Option,
 
    Upload,
    CloseOutlined,
    Image,

  },
  data() {
    return {
      formState: {
        // isPackaging: 0,
        // brandName: "",
        // brandNameList: [],
        // categoryName: "",
        // categoryNameList: [],
        // packSpCount: '',
        // packSpId: '',
        // packSpName: '',
        // salesPrice: '',
        // shelfLife: '',
        // size: '',
        // sizeHigh: '',
        // sizeLong: '',
        // sizeWide: '',
        // spNickName: '',
        // stockUnitCode: "",
      },
      rules: AreaRules,
      //是否关联包装单位
      // relation: false,
      // showUnclassified: true,
      // brandUnclassified: true,
      // imgFlag: true,
      // notUploaded: true,
      // nowDate: "",
      // fileUrl: null,
      // filelist: [],
    };
  },
  methods: {
    onSubmit() {
      (this as any).$refs.formRef
        .validate()
        .then(() => {
          let parameters: any = { ...this.formState };
           
          if (this.$route.params.code != "new") {
            updateAreaByCode({
              code: this.$route.params.code,
              province: parameters.province,
              provinceCode: parameters.provinceCode,
              city: parameters.city,
              cityCode: parameters.cityCode,
              area: parameters.area,
              areaCode: parameters.areaCode,
              town: parameters.town,
              townCode: parameters.townCode,
              level: parameters.level,
              memo: parameters.memo,
            }).then((res) => {
               
              if (res.data.success) {
                message.success("编辑地区成功");
                this.$router.push("/masterData/area/list");
              }
            });
          } else {
             
            if(parameters.city===''){
              alert('city输入框为空')
              return;
            }
            saveArea({ //saveArea
              code: parameters.code,
              province: parameters.province,
              provinceCode: parameters.provinceCode,
              city: parameters.city,
              cityCode: parameters.cityCode,
              area: parameters.area,
              areaCode: parameters.areaCode,
              town: parameters.town,
              townCode: parameters.townCode,
              level: parameters.level,
              memo: parameters.memo,
            }).then((res) => {
               
              if (res.data.success) {
                message.success("新增地区成功");
                this.$router.push("/masterData/area/list");
              }
              // else {
              //   message.error(res.data.errorMessage);
              // }
            });
          }
        })
        .catch(() => {
          message.warning("录入信息不规范请修改");
        });
    },
    cancel() {
      history.go(-1);}
    },
  mounted() {
    
    if (this.$route.params.code !== "new") {
      (this as any).$store.commit("changeBreadcrumb", [
        "主数据中心",
        "地区管理",
        "地区详情",
      ]);
      //获取数据
      getAreaByCode(this.$route.params.code).then((res: any) => {
        let result: any = res.data.data;
         
        this.formState=result
        // for (var attr in result) {
        //   if (typeof result[attr] === "number") {
        //     result[attr] = String(result[attr]);
        //   }
        //   if (result[attr] === null) {
        //     result[attr] = "";
        //   }
        // }
        // result.categoryNameList = result.categoryName.split("/");
        // result.brandNameList = result.brandName.split("/");
        // this.formState = result;
        // this.imgFlag = !this.checkImg((this as any).formState.imgUrl);
        // if ((this as any).formState.isPackaging == 0) {
        //   this.relation = false;
        // } else {
        //   this.relation = true;
        // }
      });
    } else {
      (this as any).$store.commit("changeBreadcrumb", [
        "主数据中心",
        "地区管理",
        "新增地区",
      ]);
    }
  },
});
